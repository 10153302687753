.users-form {
  width: 1440px;
  display: block;
  margin: 0 auto;
  padding-bottom: 64px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  h1 {
    margin-bottom: 24px;

    @media screen and (max-width: 767px) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  h5 {
    margin-bottom: 8px;
  }

  .d-flex {
    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  .button {
    width: auto;
    min-width: 150px;
    margin-right: 28px;

    @media screen and (max-width: 767px) {
      margin-right: 0;
      max-width: 335px;
      width: 100%;
      margin: 0 auto;
      margin-bottom: 10px;
    }
  }

  & .button[type="submit"] {
    background-color: $color-blue;
  }

  .input-text {
    width: 374px;

    @media screen and (max-width: 767px) {
      width: 100%;
      box-sizing: border-box;
    }
  }

  .form__select {
    @media screen and (max-width: 767px) {
      box-sizing: border-box;
      width: 100%;
    }
  }

  .form__item-block {
    margin-bottom: 28px;
  }

  .form__two-blocks-container {
    align-items: flex-start;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  label {
    font-size: 14px;
    &:first-of-type {
      margin-right: 44px;
    }
  }

  .radio-role {
    width: 374px;
    border: 2px solid $color-light-grey;
    border-radius: 12px;

    &__block {
      padding: 12px 20px 17px 20px;
    }

    h5 {
      margin-bottom: 4px;
      line-height: 24px;
    }

    ul {
      li {
        color: $color-purple-grey;

        &:first-of-type {
          margin-bottom: 7px;
        }
      }
    }

    .selected {
      border-color: $color-blue;
      transition: border-color 0.2s ease-in-out;
    }
  }

  .form__left-block {
    @media screen and (max-width: 767px) {
      order: 2;
    }

    .user-type {
      margin-bottom: 38px;
    }

    .user-role {
      label {
        @media screen and (max-width: 767px) {
          width: 100%;
        }

        &:first-of-type {
          margin-top: 0px;
        }
      }
    }
  }

  .form__right-block {
    @media screen and (max-width: 767px) {
      order: 1;
      width: 100%;
    }
  }
}
