.policies-form__container {
  h1 {
    margin-bottom: 24px;
  }

  .button {
    width: 150px;
  }

  input {
    width: 100%;

    @media screen and (max-width: 767px) {
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      width: 100%;
    }
  }

  .calendar__input {
    width: calc(182px + 13px) !important;
  }

  .notifications__block {
    @include flex($align: center);
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0;
    }

    span {
      margin-left: 16px;
    }

    svg {
      margin-left: 29px;
      cursor: pointer;

      &:hover {
        path {
          fill: $color-blue;
        }
      }
    }
  }

  .drop-list {
    &__button {
      margin: 0;
      width: calc(120px + 8px);
    }

    &__label {
      .input-text {
        padding: 0;
        width: 55px !important;
        box-shadow: none;
        border-radius: 0;

        &:focus {
          border: 1px solid transparent;
        }

        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
      svg {
        margin: 0px;
      }
    }

    &__popup {
      width: 120px;
    }
  }
}

.policy-status {
  display: inline-flex;
  align-items: center;
  padding: 8px 20px 7px 12px;
  border-radius: 12px;
  background-color: lightblue;
  margin-bottom: 28px;

  &__rectangle {
    width: 8px;
    height: 8px;
    background-color: $color-black;
    border-radius: 2px;
    margin-right: 11px;
  }

  &__title {
    font-size: 13px;
    line-height: 17px;
  }

  &_yellow {
    background-color: rgba(254, 189, 91, 0.1);

    .policy-status {
      &__rectangle {
        background-color: $color-warn;
      }

      &__title {
        color: rgba($color-black, 0.5);
      }
    }
  }

  &_green {
    background-color: rgba(115, 214, 202, 0.1);

    .policy-status {
      &__rectangle {
        background-color: $color-turquoise;
      }

      &__title {
        color: $color-turquoise;
      }
    }
  }

  &_red {
    background-color: $color-expired;

    .policy-status {
      &__rectangle {
        background-color: $color-dark-pink;
      }

      &__title {
        color: $color-dark-pink;
      }
    }
  }
}
