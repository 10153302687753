.form__item-block {
  div.twitter-picker {
    background: #fff !important;
    box-shadow: 2px 2px 12px rgba(111, 116, 162, 0.1) !important;
    border-radius: 12px !important;
    border: 1px solid transparent !important;
    padding: 5px 10px !important;
    font-size: 14px !important;
    line-height: 24px !important;
    width: 374px !important;
  }
}
