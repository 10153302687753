.notifications-picker {
  min-width: 374px;

  &__label {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: $color-black;
    margin-bottom: 8px;
  }

  &__notification {
    margin-bottom: 20px;
  }

  &__select-input {
    background: $color-white;
    box-shadow: 2px 2px 12px rgba(111, 116, 162, 0.1);
    border-radius: 12px;
    height: 44px;
    display: flex;
    align-items: center;
    width: 120px;
    margin-right: 16px;
    cursor: pointer;
    position: relative;

    p {
      margin-left: 20px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $color-black;
    }
  }

  &__arrow-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 20px;
    transition: all 0.2s;
  }

  &__arrow-icon--open {
    transform: translateY(-100%) rotate(180deg);
  }

  &__select-wrapper {
    display: flex;
    align-items: center;
  }

  &__options {
    overflow: hidden;
    box-shadow: 2px 2px 6px rgba(120, 122, 143, 0.1);
    border-radius: 12px;
    background-color: $color-white;
    width: 120px;
    position: absolute;
    z-index: 1;
  }

  &__select-placeholder {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-right: 20px;
  }

  &__option {
    height: 44px;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:not(:last-of-type) {
      border-bottom: 1px solid $color-grey;
    }

    &:hover {
      background-color: $color-light-blue;
    }

    p {
      margin-left: 20px;
    }
  }

  &__delete-icon {
    cursor: pointer;
  }

  &__add-notifications {
    display: flex;
    margin-top: 22px;
    align-items: center;
    cursor: pointer;

    p {
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      color: $color-blue;
      margin-left: 8px;
    }
  }
}
