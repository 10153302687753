.filter {
  min-width: 104px;
  cursor: pointer;
  position: relative;

  &__label {
    height: 44px;
    display: flex;
    align-items: center;
    padding: 0 17px 0 20px;
    background: $color-white;
    box-shadow: 2px 2px 12px rgba(111, 116, 162, 0.1);
    border-radius: 12px;
    position: relative;

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $color-black;
    }
  }

  &__options {
    width: 208px;
    background: $color-white;
    border: 1px solid $color-grey;
    box-shadow: 2px 2px 6px rgba(120, 122, 143, 0.1);
    border-radius: 12px;
    position: absolute;
    z-index: 1;

    @media screen and (max-width: 767px) {
      z-index: 99;
    }
  }

  &__option {
    height: 44px;
    display: flex;
    align-items: center;
    transition: all 0.2s;

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $color-black;
      margin-left: 20px;
    }

    &:not(:last-of-type) {
      border-bottom: 1px solid $color-grey;
    }

    &:hover {
      background-color: $color-light-blue;
    }
  }

  &__icon {
    transition: all 0.3s;
    margin-left: 16px;

    @media screen and (max-width: 767px) {
      margin-left: 10px;
    }
  }

  &__icon--open {
    transform: translateY(-30%) rotate(180deg);
  }
}
