.profile {
  border: 1px solid $color-grey;
  padding: 24px 32px 31px 30px;
  background-color: $color-white;
  box-shadow: 2px 2px 6px rgba(120, 122, 143, 0.1);
  border-radius: 12px;

  @media screen and (max-width: 767px) {
    padding: 20px;
  }

  &__title {
    margin-bottom: 8px;
    font-size: 16px;
    line-height: 24px;
  }

  &__email {
    margin-bottom: 2px;
    font-size: 14px;
    line-height: 24px;
  }

  &__billing {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  &__actions {
    display: flex;
    align-items: center;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      justify-content: center;
    }
  }

  &__change-plan {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-right: 56px;

    @media screen and (max-width: 767px) {
      margin: 0;
      width: 295px;
      height: 44px;
      border: 1px solid $color-blue;
      border-radius: 12px;
      justify-content: center;
      margin-bottom: 12px;
    }
  }

  &__change-plan-icon {
    margin-right: 8px;
  }

  &__change-plan-text {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: $color-dark-blue;
  }

  &__logout {
    cursor: pointer;
    display: flex;
    align-items: center;

    @media screen and (max-width: 767px) {
      margin: 0;
      width: 295px;
      height: 44px;
      border: 1px solid $color-blue;
      border-radius: 12px;
      justify-content: center;
      margin-bottom: 12px;
    }
  }

  &__logout-icon {
    margin-right: 8px;
  }

  &__logout-text {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: $color-dark-blue;
  }
}
