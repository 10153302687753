.switch__container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  border-radius: 12px;
  background-color: $color-white;

  .switch {
    @include flex($align: center, $justify: center);
    width: 160px;
    padding: 10px 0;
    background-color: $color-white;
    border-radius: 12px;

    input {
      display: none;
    }
  }

  .selected {
    background-color: $color-blue;
    color: $color-white;
    border-radius: 12px;
    transition: background-color 0.2s;
  }
}
