$color-blue: var(--color-blue);
$color-dark-blue: var(--color-dark-blue);

body {
}

$color-purple-grey: #9d9aba;
$color-dark-pink: #e33473;
$color-light-blue: #eef3ff;
$color-filter-popup: #f6f7fb;
$color-grey: #eceef5;
$color-turquoise: #73d6ca;
$color-expired: #f4edf4;
$color-white: #fff;
$color-light-grey: #dde0e9;
$color-black: #191827;
$color-warn: #febd5b;

$input-side-padding: 20px;
