.custom-filter {
  padding: 20px 52px 26px 24px;
  border: 1px solid $color-grey;
  box-shadow: 2px 2px 6px rgba(120, 122, 143, 0.1);
  border-radius: 12px;
  background-color: $color-filter-popup;

  @media screen and (max-width: 767px) {
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__close-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -20px;
    height: 10px;
    width: 10px;
  }

  &__label {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 16px;
  }

  &__field {
    background: $color-white;
    box-shadow: 2px 2px 12px rgba(111, 116, 162, 0.1);
    border-radius: 12px;
    height: 44px;
    display: flex;
    align-items: center;
    width: 372px;
    margin-bottom: 20px;
    position: relative;
    cursor: pointer;

    @media screen and (max-width: 767px) {
      width: 100%;
    }

    p {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $color-black;
      margin-left: 20px;
    }
  }

  &__field--open {
    .custom-filter__arrow-icon {
      transform: rotate(180deg) translateY(50%);
    }
  }

  &__field--with-round-border {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__arrow-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.3s;
    right: 17px;
  }

  &__input {
    border: none;
    width: 100%;
    height: 100%;
    background: $color-white;
    box-shadow: 2px 2px 12px rgba(111, 116, 162, 0.1);
    border-radius: 12px;
    padding: 0 20px;
    outline: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $color-black;

    &::placeholder {
      color: $color-purple-grey;
    }
  }

  &__placeholder {
    color: $color-purple-grey;
  }

  .button {
    width: 132px;
    background-color: $color-blue;
  }

  &__companies {
    background: $color-white;
    box-shadow: 2px 2px 6px rgba(120, 122, 143, 0.1);
    border-radius: 12px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 1;
    max-height: 180px;
    overflow-y: scroll;
    cursor: pointer;
  }

  &__company {
    width: 100%;
    height: 44px;
    display: flex;
    align-items: center;

    &:not(:last-of-type) {
      border-bottom: 1px solid $color-grey;
    }

    &:hover {
      background-color: $color-light-blue;
    }
  }

  &__dates {
    justify-content: space-between;
    display: flex;
    margin-bottom: 20px;
    position: relative;
    & .date-field {
      width: 100%;
    }
    & .date-field:first-of-type {
      margin-right: 20px;
    }

    @media screen and (max-width: 767px) {
      flex-wrap: wrap;

      & .date-field {
        margin-top: 12px;
        width: 100%;

        &__field {
          width: 100%;
        }

        .react-datepicker-wrapper {
          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }
      }
    }
  }
}

.react-datepicker__month-container {
  @media screen and (max-width: 767px) {
    float: none !important;
  }
}
