.billing-success {
  padding-top: 190px;

  &__title {
    font-size: 36px;
    line-height: 46px;
    font-weight: 500;
    color: $color-black;
    text-align: center;
    margin-bottom: 17px;
  }

  &__subtitle {
    font-size: 21px;
    line-height: 34px;
    font-weight: 500;
    color: $color-purple-grey;
    margin-bottom: 42px;
    text-align: center;
  }

  &__button {
    max-width: 210px;
    margin: 0 auto;
  }

  &__icon {
    display: flex;
    justify-content: center;
    margin-bottom: 27px;
  }
}