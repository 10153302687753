.header {
  display: block;
  width: 100vw;

  &.mobileOpened {
    @media screen and (max-width: 767px) {
      overflow: scroll;
      position: fixed;
      height: 115vh;
      top: 0;
      background-color: $color-white;
      z-index: 99;
    }
  }

  &__profile-content {
    position: absolute;
    top: 85%;
    right: 129px;
    transition: all 0.2s;
    opacity: 1;
    transition: all 0.2s;
    z-index: 1;

    @media screen and (max-width: 767px) {
      left: 21px;
      min-width: 310px;
    }
  }

  &__profile {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 80%;

    @media screen and (max-width: 767px) {
      display: none;
      position: absolute;
      top: 444px;
      left: 0;
      width: 100%;
      height: 70px;
      z-index: 99;
      padding-left: 21px;
      background-color: $color-white;
    }

    &.mobileOpened {
      display: flex;
    }
  }

  &__profile-icon {
    margin-right: 14.4px;
  }

  &__profile-text {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
  }

  &__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 88px;
    background-color: $color-white;
    padding: 0 120px;
    position: relative;

    @media screen and (max-width: 767px) {
      height: 68px;
      padding: 0 20px;
      position: static;
      border-bottom: 1px solid $color-light-grey;
    }
  }

  &__logo {
    max-width: 133px;
    width: 100%;

    @media screen and (max-width: 767px) {
      max-width: 108px;
    }

    svg {
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }
  }

  &__nav {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      position: absolute;
      top: 69px;
      left: 0;
      right: 0;
      height: 140%;
      z-index: 99;
      background-color: $color-white;
    }

    &.mobileClosed {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    &-item {
      position: relative;
      margin-top: 6px;
      padding-bottom: 6px;
      cursor: pointer;
      font-weight: bold;
      font-size: 16px;
      border-bottom: 3px solid transparent;
      transition: all 0.2s ease-in-out;

      @media screen and (max-width: 767px) {
        display: inline-block;
      }

      &_active {
        li {
          color: $color-blue !important;
          border: 0 !important;
          border-bottom: 3px solid $color-blue !important;
          transition: all 0.2s ease-in-out;
        }
      }
    }

    a {
      margin-right: 50px;

      &:last-of-type {
        margin-right: 0px;
      }

      @media screen and (max-width: 767px) {
        height: 69px;
        border-bottom: 1px solid $color-light-grey;
        margin-right: 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding-left: 21px;
      }

      & svg {
        margin-right: 56px;
      }
    }
  }

  &__burger {
    display: none;
    width: 40px;
    height: 40px;

    @media screen and (max-width: 767px) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__menu {
    @include flex($align: center, $justify: flex-end);
    right: 120px;
    cursor: pointer;

    &:hover {
      svg,
      path {
        fill: $color-dark-blue;
      }
    }

    span {
      font-weight: bold;
    }

    svg {
      cursor: pointer;

      path {
        transition: background-color 0.2s ease-in-out;
      }

      &:last-of-type {
        margin-left: 14px;
      }
    }
  }
}

.header-notification {
  width: 100%;
  background-color: $color-dark-pink;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 767px) {
    padding: 0 5px;
    box-sizing: border-box;
  }

  p {
    color: $color-white;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin-right: 12px;

    @media screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 21px;
      max-width: 200px;
    }
  }

  &__button {
    width: 130px;
    height: 34px;
    border: 1px solid $color-white;
    color: $color-white;
    border-radius: 12px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__button:hover {
    transform: none;
  }
}
