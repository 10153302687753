.reviewing__container {
  min-width: 1200px;
  max-width: 100%;
  margin: 0 auto;

  .button {
    width: 150px;
    &:last-of-type {
      margin-left: 28px;
    }
  }

  .table {
    thead {
      tr {
        td {
          span {
            margin-right: 0;
          }
          &:last-of-type {
            padding-right: 20px !important;
          }
        }
      }
    }
    tbody {
      tr {
        td {
          &:first-of-type {
            width: auto;
          }

          &:last-of-type {
            margin-right: 20px;
          }
        }
      }
    }

    &__icons {
      svg:last-of-type {
        margin-right: 48px;
      }
    }

    &__icons-cell {
      justify-content: flex-end;
    }
  }
}
