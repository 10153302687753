.form-date-selector--disabled {
  .form-date-selector__field {
    background-color: $color-grey;
    border: 1px solid $color-grey;
    color: $color-purple-grey;
    cursor: initial;
    box-shadow: none;
  }
}

.form-date-selector {
  &__field {
    background-color: $color-white;
    box-shadow: 2px 2px 12px rgba(111, 116, 162, 0.1);
    border-radius: 12px;
    border: 1px solid $color-white;
    padding: 10px 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $color-black;
    width: inherit !important;
    display: inline-block;
    cursor: pointer;

    &::placeholder {
      color: $color-purple-grey;
    }
  }

  &__field-wrapper {
    position: relative;
  }

  &__calendar-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
  }

  &__fields {
    display: flex;
  }

  &__field-wrapper--date {
    // max-width: 184px;
    margin-right: 10px;
  }

  &__field--time {
    max-width: 85px;
  }
}
