.login-title {
  @media screen and (max-width: 767px) {
    margin: 0 auto;
    margin-top: 28px;
    margin-bottom: 18px !important;
    font-size: 24px;
    line-height: 30px;
    text-align: center;
    font-weight: 600;
  }
}

.button.disabled {
  position: relative;
  background-color: $color-light-grey;
  color: $color-black;

  svg.loading-icon {
    position: absolute;
    top: 50%;
    right: 40%;
    opacity: 1;
    animation: rotation 1s linear infinite;

    & path {
      fill: $color-black;
    }

    &:hover path {
      fill: $color-black !important;
    }
  }
}

.loading-icon {
  position: absolute;
  opacity: 0;
  margin: 0 !important;
}

@keyframes rotation {
  from {
    transform: translateY(-50%) rotate(0deg);
  }
  to {
    transform: translateY(-50%) rotate(-360deg);
  }
}
