.drop-list {
  &__button {
    padding: 7px 8px 7px 20px;
    position: relative;
    margin-left: 20px;
    background: $color-white;
    box-shadow: 2px 2px 12px rgba(111, 116, 162, 0.1);
    border-radius: 12px;
    cursor: pointer;

    &:first-of-type {
      margin-left: 40px;
    }
  }

  &__label {
    @include flex($align: center, $justify: space-between);

    svg {
      margin-left: 12px;
      transition: transform 0.1s ease-in-out;
      &.rotated {
        transform: rotate(-180deg);
      }
    }
  }

  &__popup {
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 10;
    background: $color-white;
    border: 1px solid $color-light-blue;
    box-shadow: 2px 2px 6px rgba(120, 122, 143, 0.1);
    border-radius: 12px;
    overflow: hidden;
    text-align: left;

    ul {
      overflow: hidden;
      li {
        padding: 10px 20px;
        height: 44px;
        @include flex($align: center);
        border-bottom: 1px solid $color-light-grey;
        transition: background-color 0.07s ease-in-out;

        &:hover {
          background-color: $color-light-blue;
        }

        &:last-of-type {
          border-bottom: 0;
        }
      }
      .custom {
        border-top: 1px solid $color-purple-grey;
        font-weight: bold;
      }
    }
  }

  .filtered {
    min-width: 208px;
    width: 100%;
  }

  .grouped {
    min-width: 152px;
    width: 100%;
  }
}
