@mixin flex($direction: row, $align: initial, $justify: initial) {
  display: flex;
  flex-direction: $direction;
  align-items: $align;
  justify-content: $justify;
}

.text-center {
  text-align: center;
}
