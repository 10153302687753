.toggle {
  position: relative;
  display: inline-flex;
  width: 28px;
  height: 16px;
  background-color: $color-light-grey;
  border: 2px solid $color-light-grey;
  border-radius: 11px;
  align-items: center;
  transition: all 0.3s;
  cursor: pointer;

  &__slider {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    background-color: $color-white;
    display: inline-block;
    position: absolute;
    left: 0;
    transition: all 0.3s;
  }
}

.toggle--active {
  background-color: $color-blue;
  border-color: $color-blue;

  .toggle__slider {
    left: 100%;
    transform: translateX(-100%);
  }
}
