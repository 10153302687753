.table-editor {
  position: relative;

  &__field {
    background-color: $color-white;
    box-shadow: 2px 2px 12px rgba(111, 116, 162, 0.1);
    border-radius: 12px;
    height: 44px;
    width: 136px;
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
      margin-left: 20px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: $color-black;
      margin-right: 8px;
    }
  }

  &__settings {
    padding: 28px 44px 36px 40px;
    background-color: $color-filter-popup;
    width: 416px;
    border: 1px solid $color-grey;
    box-shadow: 2px 2px 6px rgba(120, 122, 143, 0.1);
    border-radius: 12px;
    position: absolute;
    top: 100%;
    z-index: 1;

    @media screen and (max-width: 767px) {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
  }

  &__settings-title {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: $color-black;
  }

  &__reset {
    cursor: pointer;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: $color-blue;
  }

  &__field-item {
    width: 100%;
    margin-bottom: 12px;

    &:last-of-type {
      margin-bottom: 20px;
    }
  }

  .button {
    max-width: 132px;
    background-color: $color-blue;
  }
}

.editor-field {
  height: 44px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: $color-white;
  align-items: center;
  box-shadow: 2px 2px 12px rgba(111, 116, 162, 0.1);
  border-radius: 12px;
  border: 1px solid $color-white;
  transition: all 0.2s;
  cursor: pointer;
  padding-right: 16px;

  &__name {
    display: flex;
    align-items: center;
    margin-left: 12px;
  }

  &:hover {
    border: 1px solid $color-blue;
  }

  p {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $color-black;
    margin-left: 8px;
  }
}
