.calendar__container {
  @include flex($align: center);

  .switch__container {
    margin-left: 12px;
    .switch {
      width: 39px;
      font-size: 14px;
      line-height: 21px;
      font-weight: 500;
      border: 2px solid transparent;
    }

    .selected {
      background-color: rgba(20, 77, 222, 0.06);
      color: $color-blue;
      font-weight: 700;
    }
  }

  &_disabled {
    .calendar__input {
      background-color: $color-grey;
      box-shadow: none;
      cursor: auto;

      input {
        background-color: $color-grey;
      }

      &:hover > svg path {
        fill: $color-purple-grey;
      }
    }

    .calendar__time {
      background-color: $color-grey;
      box-shadow: none;
    }

    .switch__container {
      box-shadow: none;
      background-color: $color-grey !important;
    }
    .switch {
      background-color: $color-grey !important;
      color: $color-purple-grey !important;
    }

    .selected {
      border-color: $color-light-grey !important; 
    }
  }
}

.form {
  margin-bottom: 12px;
}

.calendar {
  margin: 20px 0;
  @include flex($align: center, $justify: space-between);

  &__input {
    width: 180px;
    @include flex($align: center, $justify: space-between);
    cursor: pointer;
    padding-right: 13px;
    color: $color-purple-grey;

    &:hover > svg path {
      fill: $color-dark-blue;
    }

    svg {
      margin-bottom: 4px;
    }

    input {
      border: 0;
      width: 125px !important;
      font-size: 14px;

      &::placeholder {
        color: $color-purple-grey;
      }
    }
  }

  &__time {
    outline: none;
    width: 42px !important;
    margin-left: 12px;
  }
}
