.free-plan-warning {
  width: 100%;
  height: 60px;
  background-color: $color-turquoise;
  @include flex($align: center, $justify: center);

  p {
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: $color-white;
    margin-right: 12px;
  }

  button {
    width: 142px;
    border: 1px solid $color-white;

    &:hover {
      cursor: pointer;
      background-color: rgba($color-black, 0.05);
    }
  }
}

.ended {
  background-color: $color-dark-pink;
}

.closed {
  opacity: 0;
  transform: translateY(-100%);
}

@keyframes dropout {
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  50% {
    opacity: 0.7;
    transform: translateY(-50%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
