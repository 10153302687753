.table__container {
  margin-top: 32px;
  overflow-x: auto;
  position: relative;
}

.table {
  width: 100%;
  min-width: 1200px;
  margin-bottom: 20px;
  border-collapse: collapse;
  border-radius: 2px;
  position: relative;

  &__notifications-count {
    position: absolute;
    color: $color-white;
    font-size: 9px;
    font-weight: 800;
    background-color: $color-purple-grey;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 15px;
    width: 15px;
    border-radius: 50%;
    top: -5px;
    right: -5px;
  }

  &__notifications-icon {
    position: relative;
    width: 16px;
    height: 25px;

    &:hover {
      .table__notifications-count {
        background-color: $color-dark-blue;
      }

      svg > path {
        fill: $color-dark-blue;
      }
    }
  }

  p {
    font-size: 14px;
    line-height: 24px;
  }

  &__name-cell {
    font-weight: bold;
    min-width: 256px;
  }

  &__number-cell {
    min-width: 142px;
  }

  &__default-icon {
    margin-bottom: -3.5px;
    margin-right: 3px;

    &:hover {
      cursor: pointer;

      rect {
        fill: $color-purple-grey;
      }

      path {
        fill: $color-grey;
      }
    }
  }

  &__attachment {
    margin-right: 23px;
    font-size: 13px;
  }

  &__popup-icon {
    display: block;
    padding: 0 !important;
    margin-top: 6px;
  }

  &__status-cell {
    width: 3px !important;
    padding-left: 0 !important;
    padding-right: 13px !important;
    padding-top: 0px !important;
  }

  &__date-cell {
    padding-right: 0px !important;
  }

  &__date {
    @include flex($align: center);
  }

  &__time {
    color: $color-purple-grey;
  }

  &__notes {
    min-width: 100px;
    font-size: 14px;
  }

  &__icons-cell {
    @include flex($align: flex-start, $justify: space-between);
    padding-right: 0 !important;

    span {
      margin-top: 5px;
    }
  }

  &__expired {
    color: $color-dark-pink;
  }

  &__icons {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    margin-top: 5px;
    margin-left: 37px;
    position: relative;
    z-index: 10;

    a {
      svg {
        margin-right: 0;
      }
    }

    svg {
      margin-right: 48px;
      cursor: pointer;

      &:last-of-type {
        margin-top: 2px;
        margin-right: 0px;
      }
    }
  }

  &__notif-block {
    position: relative;
    margin-right: 48px;

    div {
      width: 28px;
    }

    svg {
      margin-right: 0 !important;
    }

    &:hover {
      svg {
        path {
          fill: $color-dark-blue;
        }
      }
      span {
        background-color: $color-blue;
      }
    }
  }

  &__notification {
    position: absolute;
    left: 10px;
    top: -6px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15px;
    height: 15px;
    border-radius: 75px;
    background-color: $color-purple-grey;
    color: $color-grey;
    font-size: 10px;
    font-weight: bold;
    line-height: 10px;
    cursor: pointer;
  }

  thead {
    color: $color-purple-grey;

    tr {
      height: 43px;
      background-color: $color-grey;
      white-space: nowrap;
      td {
        font-size: 13px;
        line-height: 17px;
        font-weight: 500;
        white-space: nowrap;
        padding-right: 35px;

        &:first-of-type {
          position: relative;
          padding-left: 16px;
        }

        &:last-of-type {
          padding-right: 23px;

          svg {
            padding-left: 23px;
          }
        }

        span {
          margin-right: 5px;
        }

        svg {
          &:hover {
            fill: $color-black;
          }
        }
      }
    }
  }

  tbody {
    tr {
      box-shadow: 2px 2px 6px rgba(120, 122, 143, 0.1);
      border-radius: 2px;
      background-color: $color-white;
      border-bottom: 2px solid rgba(120, 122, 143, 0.1);
      white-space: nowrap;

      &:hover {
        background-color: $color-light-blue;
      }

      td {
        height: 63px;
        vertical-align: top;
        padding-top: 17px;
        padding-right: 22px;

        &:first-of-type {
          padding-left: 16px;
          font-weight: bold;
          width: 110px;
        }
        .table__status {
          background-color: $color-turquoise;
          height: 80px;
          width: 3px;

          &_expired {
            background-color: $color-dark-pink;
          }

          &_expired-soon {
            background-color: $color-warn;
          }
        }
      }
    }

    .table__title-row {
      background-color: rgba(236, 238, 245, 0.5);
      color: $color-purple-grey;
      box-shadow: none;
      border-bottom: none;

      &__cell {
        font-weight: 400 !important;
      }

      td {
        padding: 0;
        height: 36px;
        vertical-align: middle;
        font-size: 13px;
        line-height: 17px;
      }
    }

    .table__empty-row {
      height: 32px;

      &:first-of-type {
        height: 16px;
      }
    }
  }

  .expired {
    background-color: $color-expired;
  }
}

.status-popup {
  @include flex($justify: flex-start, $direction: column);
  margin-left: 8px;
  position: absolute;
  padding: 0;
  left: 8px;
  z-index: 10;
  width: 389px;
  border: 1px solid $color-grey;
  opacity: 0;
  visibility: hidden;
  transition: visibility, opacity 0.2s ease-in-out;

  svg {
    margin-right: 11px;
  }

  span,
  h5 {
    padding: 10px 20px;
    border-bottom: 1px solid $color-light-grey;
  }

  &__notification {
    width: auto;
    min-width: 235px;
    border-bottom: 0;
  }
}

.reviewing {
  td {
    &:first-of-type {
      padding-left: 36px !important;
    }
  }
}

.small-table {
  @media screen and (max-width: 767px) {
    display: none;
  }

  table {
    min-width: 1200px;
  }
}

.policies-edit {
  display: block;
  width: 30px;
  height: 30px;
  margin-left: 30px;
}

.small-mobile-table {
  display: none;

  &__card {
    padding: 12px 16px;
    display: flex;
    justify-content: space-between;
    background-color: $color-white;
    margin-bottom: 8px;
  }

  &__edit {
    padding-top: 5px;
  }

  &__contact {
    margin-bottom: 8px;
  }

  &__contact-head {
    font-size: 14px;
    line-height: 18px;
    color: $color-purple-grey;
    font-weight: 400;
  }

  &__contact-text-bold {
    font-weight: 700;
  }

  &__contact-text {
    font-size: 14px;
    line-height: 24px;
  }

  @media screen and (max-width: 767px) {
    display: block;
  }
}
