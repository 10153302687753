.date-field {
  &__input {
    position: relative;
  }

  &__field {
    background-color: $color-white;
    width: 100%;
    height: 44px;
    background-color: $color-white;
    box-shadow: 2px 2px 12px rgba(111, 116, 162, 0.1);
    border-radius: 12px;
    border: none;
    padding: 0 20px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $color-black;
    cursor: pointer;

    &::placeholder {
      color: $color-purple-grey;
    }
  }

  &__calendar-icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 12px;
  }
}
