.billing-card {
  border-radius: 12px;
  border: 2px solid $color-light-grey;
  padding: 28px 32px 40px;
  min-height: 358px;
  display: flex;
  flex-direction: column;
  width: 100%;

  &:hover {
    background: rgba(20, 77, 222, 0.06);
    border: 2px solid $color-blue;
  }

  .button {
    border: 1px solid $color-light-blue;
    color: $color-light-blue;
    margin-top: auto;
  }

  @media screen and (max-width: 767px) {
    padding: 15px 20px;
    width: 100%;
    max-width: 335px;
    box-sizing: border-box;
  }

  &__title {
    display: flex;
    padding-bottom: 13px;
    border-bottom: 1px solid $color-light-grey;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      font-size: 21px;
      line-height: 34px;

      @media screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }

  &__price-block {
    display: flex;
    padding-bottom: 17px;
    margin-bottom: 20px;
    border-bottom: 1px solid $color-light-grey;
  }

  &__price {
    color: $color-blue;
    font-size: 46px;
    line-height: 46px;
    font-weight: bold;
    margin-right: 8px;

    @media screen and (max-width: 767px) {
      font-size: 36px;
      line-height: 36px;
    }
  }

  &__period {
    font-size: 13px;
    line-height: 17px;
    font-weight: 500;
  }
  &__features-prefix-block {
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__limits {
    margin-bottom: 35px;

    @media screen and (max-width: 767px) {
      margin-left: 20px;
    }
  }

  &__limit {
    font-size: 14px;
    line-height: 24px;
    color: $color-black;
    list-style: disc;
    margin-left: 20px;

    p {
      @media screen and (max-width: 767px) {
        font-size: 14px;
        line-height: 21px;
      }
    }
  }

  & .button {
    @media screen and (max-width: 767px) {
      background-color: transparent;
      border: 2px solid $color-blue;
      color: $color-blue;
    }
  }

  &__limit:not(:last-of-type) {
    margin-bottom: 20px;
  }

  &__current-plan {
    padding: 7px 10px;
    background: rgba(20, 77, 222, 0.1);
    border-radius: 12px;
    font-weight: bold;
    font-size: 13px;
    color: $color-blue;
    line-height: 17px;
  }

  &_active {
    background-color: $color-grey;

    &:hover {
      background-color: $color-grey;
      border: 2px solid $color-light-grey;
    }

    .billing-card__price {
      color: $color-black;
    }
  }

  &.selected {
    background-color: $color-blue;
    color: $color-white;

    .billing-card__limit,
    .billing-card__price {
      color: $color-white;
    }

    .button {
      border: 1px solid $color-light-blue;
      color: $color-light-blue;
      background-color: $color-blue;
    }
  }
}
