.policy {
  @media screen and (max-width: 767px) {
    position: relative;
  }

  &__title {
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    color: $color-black;
    margin-bottom: 32px;
  }

  &__table {
    position: relative;
  }

  &__buttons {
    display: flex;

    @media screen and (max-width: 767px) {
      display: none;
    }

    .button {
      width: 150px;
    }

    .button:first-of-type {
      margin-right: 20px;
    }

    .button:last-child {
      background-color: $color-blue;
    }
  }

  &__buttons-mobile {
    display: none;

    @media screen and (max-width: 767px) {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 40px;
      padding: 0 20px;
      padding-bottom: 20px;
    }

    & .button {
      max-width: 335px;
      width: 100%;
    }

    & .button:first-of-type {
      margin-right: 0;
      margin-bottom: 12px;
    }
  }

  &__close-filter {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-left: 14px;
  }

  .search {
    margin-right: 40px;

    @media screen and (max-width: 767px) {
      margin-right: 0;
      width: 100%;
    }
  }

  &__controllers {
    display: flex;
    justify-content: space-between;
  }

  &__filter-option {
    height: 44px;
    display: flex;
    align-items: center;
    transition: all 0.2s;
    border-top: 1px solid $color-purple-grey;

    p {
      font-weight: bold;
      font-size: 14px;
      line-height: 24px;
      color: $color-black;
      margin-left: 20px;
    }

    &:hover {
      background-color: $color-light-blue;
    }
  }

  &__filters {
    display: flex;
    position: relative;
    margin-bottom: 32px;

    @media screen and (max-width: 767px) {
      flex-wrap: wrap;
    }
  }

  &__add-policy {
    width: 161px;

    @media screen and (max-width: 767px) {
      position: absolute;
      top: 0;
      right: 10px;
      width: 40px;
      height: 40px;
    }

    & svg {
      @media screen and (max-width: 767px) {
        margin: 0;
      }
    }

    & span {
      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    .button {
      background-color: $color-blue;

      @media screen and (max-width: 767px) {
        padding: 0;
      }
    }
  }

  &__group-by {
    min-width: 120px;
    margin-left: 20px;

    @media screen and (max-width: 767px) {
      margin-left: 12px;
      margin-top: 10px;
      margin-right: 12px;
    }
  }

  &__custom-filter {
    z-index: 1;
    top: 100%;
    left: 340px;
    position: absolute;
  }

  & .filter {
    @media screen and (max-width: 767px) {
      margin-top: 10px;
    }
  }

  &__editor {
    margin-left: 20px;

    @media screen and (max-width: 767px) {
      margin-left: 0;
      margin-top: 10px;
    }
  }
}

.policy-reviewing-empty-state {
  height: 80vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__title {
    font-weight: 500;
    font-size: 36px;
    line-height: 46px;
    color: $color-black;
    margin-bottom: 17px;
  }

  &__text {
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 34px;
    color: $color-purple-grey;
  }
}
