.pagination {
  @include flex($justify: center, $align: center);
  margin-top: 40px;
  margin-bottom: 40px;

  &__page {
    @include flex($justify: center, $align: center);
    width: 40px;
    height: 40px;
    border: 2px solid transparent;
    border-radius: 12px;
    font-weight: 500;
    cursor: pointer;

    &_selected {
      border: 2px solid $color-blue;
      font-weight: 700;
    }

    &:hover {
      background-color: $color-light-blue;
    }
  }

  svg {
    margin-right: 16px;
    cursor: pointer;

    &:last-of-type {
      margin-left: 16px;
      margin-right: 0;
      transform: rotate(180deg);
    }
  }

  &__next-page {
    path {
      fill: $color-blue;
    }
  }
}