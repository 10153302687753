.search {
  width: 300px;
  height: 44px;
  background: $color-white;
  box-shadow: 2px 2px 12px rgba(111, 116, 162, 0.1);
  border-radius: 12px;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;
  transition: width 0.3s ease;

  @media screen and (max-width: 767px) {
    &.search__small-icon {
      width: 52px;
      justify-content: center;

      & .search__field {
        display: none;
      }

      & .search__icon {
        @media screen and (max-width: 767px) {
          position: initial;
          transform: translateY(0);
        }
      }
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    left: 14px;
    transform: translateY(-50%);
  }

  &__field {
    width: 100%;
    height: 100%;
    border: none;
    outline: none;
    padding-left: 52px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $color-black;

    &::placeholder {
      color: $color-purple-grey;
    }
  }
}
