.form-company-select {
  width: 374px;
  margin-bottom: 28px;
  cursor: pointer;
  position: relative;

  @media screen and (max-width: 767px) {
    width: 100%;
    order: 1;
  }

  &__label {
    color: $color-black;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 8px;
    font-weight: bold;
    display: inline-block;
  }

  &__field {
    background-color: $color-white;
    box-shadow: 2px 2px 12px rgba(111, 116, 162, 0.1);
    border-radius: 12px;
    border: 1px solid $color-white;
    padding: 10px 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $color-black;
    box-sizing: border-box;
  }

  &__field--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &__field-wrapper {
    position: relative;
  }

  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 19px;
    cursor: pointer;
    transition: all 0.3s;
  }

  &__icon--open {
    transform: rotate(-180deg) translateY(50%);
  }

  &__options {
    background-color: $color-white;
    position: absolute;
    width: 100%;
    z-index: 1;
    box-shadow: 2px 2px 12px rgba(111, 116, 162, 0.1);
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    max-height: 150px;
    overflow-y: scroll;
  }

  &__option {
    height: 44px;
    display: flex;
    align-items: center;

    p {
      padding: 0 20px;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
    }

    &:hover {
      background-color: $color-light-blue;
    }
  }
}
