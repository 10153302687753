.phone-input {
  position: relative;
  padding: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  &__phone-code-select {
    display: inline-block;
    padding: 10px;
    padding-right: 10px;
    padding-left: 20px;
    cursor: pointer;
    flex: 0 0 auto;
  }
  &__phone-code-arrow {
    height: 40px;
    cursor: pointer;
  }
  &__phone {
    padding-left: 10px;
    outline: 0;
    border: 0;
    appearance: none;
    height: 40px;
    border-radius: 12px;
    flex: 1 1 100%;
  }
  &__phone-code-dropdown {
    background: $color-white;
    border: 1px solid $color-grey;
    box-shadow: 2px 2px 6px rgba(120, 122, 143, 0.1);
    border-radius: 12px;
    z-index: 99;

    max-height: 200px;
    width: 100%;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
  }
  &__phone-code-search {
    border-radius: 12px;
    padding: 10px 20px;
    outline: 0;
    border: 0;
    appearance: none;
    height: 40px;
  }
  &__phone-code-list {
    overflow: scroll;
  }
  &__phone-code-list-item {
    display: flex;
    padding: 20px;
    justify-content: space-between;

    height: 44px;
    display: flex;
    align-items: center;
    transition: all 0.2s;

    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $color-black;

    cursor: pointer;

    &:not(:last-of-type) {
      border-bottom: 1px solid $color-grey;
    }

    &:hover {
      background-color: $color-light-blue;
    }
  }
}
