* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;
  font-size: 16px;
}

body {
  background: $color-filter-popup;
  font-feature-settings: "lnum" 1;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

input {
  &::placeholder {
    color: $color-purple-grey;
  }
}

h1 {
  font-size: 36px;
  line-height: 55px;
  margin-top: 40px;
}

h2 {
  font-weight: 600;
  font-size: 21px;
  line-height: 34px;
  margin-top: 24px;
}

h5 {
  font-size: 14px;
  font-weight: 700;
}

span,
li,
tr,
td {
  font-size: 14px;
}

tr,
td {
  line-height: 24px;
}

button {
  width: 100%;
  height: 44px;
  border-radius: 12px;
  color: $color-white;
  font-size: 14px;
  font-weight: 700;
}

.wrapper {
  padding: 0 120px;

  @media screen and (max-width: 767px) {
    padding: 0 20px;
  }
}

.container {
  width: 480px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    width: 100%;
    display: block;
  }
}

.hidden {
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}

.visible {
  visibility: visible !important;
  opacity: 1 !important;
  transition: all 0.2s ease-in-out;
}

.blue-label {
  display: flex;
  align-items: center;
  color: $color-blue;
  cursor: pointer;
  font-weight: bold;
  margin-top: 22px;
  font-size: 14px;

  svg {
    margin-right: 8px;
    path {
      fill: $color-blue;
    }
  }
}

.pointer {
  cursor: pointer;
}

.white-block {
  background-color: $color-white;
  box-shadow: 2px 2px 12px rgba(111, 116, 162, 0.1);
  border-radius: 12px;
  border: 1px solid transparent;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 24px;
}
.react-datepicker-wrapper {
  width: initial;
}
.react-datepicker {
  width: 340px;
  height: auto;
  background: $color-white;
  box-shadow: 2px 2px 12px rgba(111, 116, 162, 0.1);
  border-radius: 12px;
  border: 0;
  font-family: "Raleway", sans-serif;
  font-size: 14px;

  &__triangle {
    transform: 0;
    display: none;
  }

  &__header {
    background-color: $color-white;
    border-bottom: 0;
    padding: 0;
    padding-top: 19px;
  }

  &__navigation-icon {
    top: 10px;
  }

  .react-datepicker__year-read-view--down-arrow,
  .react-datepicker__month-read-view--down-arrow,
  .react-datepicker__month-year-read-view--down-arrow,
  .react-datepicker__navigation-icon::before {
    border-color: $color-blue;
    width: 6px;
    height: 6px;
  }

  &__current-month {
    font-size: 14px;
  }

  &__day-names {
    margin-top: 15px;
  }

  &__day-name {
    color: $color-purple-grey;
    font-size: 13px;
    line-height: 17px;
    width: auto;
    margin: 0;
    margin-right: 27px;

    &:last-of-type {
      margin-right: 0;
    }
  }

  &__month {
    margin: 20px 16px 16px 16px;
  }

  &__day {
    width: 44px;
    height: 44px;
    border-radius: 12px;
    margin: 0;
    font-size: 14px;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    &--keyboard-selected {
      color: $color-black;
      background-color: $color-white;
    }

    &:hover {
      background-color: $color-light-blue;
      border-radius: 12px;
    }

    &--outside-month {
      opacity: 0;
      visibility: hidden;
    }

    &--today {
      background: $color-light-blue;
    }

    &--selected {
      height: 40px;
      width: 40px;
      font-weight: bold;
      background-color: $color-white;
      color: $color-black;
      border: 2px solid $color-blue;

      &:hover {
        background-color: $color-white;
        border-radius: 12px;
      }
    }
  }
}
