.custom-popup {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 10;
  width: 448px;
  padding: 20px 52px 24px 24px;
  background-color: $color-filter-popup;
  border: 1px solid $color-grey;
  box-shadow: 2px 2px 6px rgba(120, 122, 143, 0.1);
  border-radius: 12px;

  .button {
    width: 132px;
  }

  .input-text {
    width: 372px;
    margin-top: 16px;
  }

  &__input-block {
    position: relative;
  }

  &__names-list {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding: 0;
    z-index: 10;

    li {
      padding: 10px 20px;
      border-bottom: 1px solid $color-light-grey;
      transition: background-color 0.1s ease-in-out;

      &:last-of-type {
        border-bottom: 0;
      }

      &:hover {
        background-color: $color-light-blue;
      }
    }
  }
}
