.form {
  width: 100%;

  .button {
    width: 100%;
  }

  &__buttons {
    display: flex;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__input-box {
    @media screen and (max-width: 767px) {
      margin-bottom: 85%;
    }
  }

  &__two-blocks-container {
    @include flex($align: flex-start);
  }

  &__left-block {
    margin-right: 60px;

    @media screen and (max-width: 767px) {
      margin-right: 0;
      width: 100%;
    }
  }

  &__right-block {
    position: relative;
  }

  &__item-block {
    margin-bottom: 28px;
    position: relative;

    .blue-label {
      margin-bottom: 38px;
    }

    &_radio {
      margin-bottom: 28px;
      position: relative;
      @include flex($direction: column);
    }
  }

  &__label-block {
    @include flex($justify: space-between);
  }

  &__error {
    color: $color-dark-pink;
    display: block;
    margin-top: 10px;
  }

  h5 {
    margin-bottom: 8px;
  }

  &__caption {
    position: absolute;
    bottom: 6px;
    left: 0;
    font-size: 13px;
    line-height: 17px;
    color: $color-purple-grey;
  }

  &__subtitle {
    width: 100%;
    @include flex($align: center);
    margin-top: 40px;
    margin-bottom: 100px;
    font-weight: 700;
    font-size: 14px;

    @media screen and (max-width: 767px) {
      margin-top: 10px;
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
    }
  }
  &__label--blue {
    color: $color-blue;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;

    &:hover {
      color: $color-dark-blue;
    }

    a {
      font-size: 14px;
    }
  }

  &__textarea {
    width: 374px !important;
    height: 116px;
    resize: none;
    outline: none;
    border: none;

    &:focus {
      outline: none;
      border: none;
    }
  }

  &__input-file__label {
    @include flex($align: center, $justify: space-between);
    padding-right: 11px;
    cursor: pointer;

    &:hover > svg path {
      fill: $color-dark-blue;
    }

    &_not-empty {
      cursor: auto;
      padding-top: 7px;
      padding-bottom: 7px;

      &:hover > svg path {
        fill: $color-purple-grey;
      }

      svg {
        &:hover {
          path {
            fill: $color-dark-blue;
          }
        }
      }
    }
  }

  &__input-file {
    visibility: hidden;
    opacity: 0;
    display: none;
  }

  &__file-label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    width: 310px;
  }

  &__button-block {
    @include flex($align: center);

    .button {
      &:first-of-type {
        margin-right: 28px;
      }
    }
  }

  .toggle {
    margin-right: 10px;
  }
}

.form__select {
  max-width: 360px;
  height: 44px;
  position: relative;
}

.form__select select {
  appearance: none;
  width: 100%;
  height: 100%;
  background-color: $color-white;
  border-radius: 12px;
  padding-left: 18px;
  border: none;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}

.form__select-arrow {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  transition: transform 0.1s ease-in-out;

  &.rotate {
    transform: translateY(-50%) rotate(-180deg);
  }
}
