.history {
  color: $color-purple-grey;
  margin-top: 28px;

  span {
    font-weight: 500;
    font-size: 13px;
  }

  &__link {
    color: $color-blue;
    cursor: pointer;

    a {
      font-weight: 500;
      font-size: 13px;
    }
  }

  svg {
    margin: 0 11px;
  }
}
