.textarea {
  &__label {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    color: $color-black;
    margin-bottom: 8px;
  }

  &__field {
    padding: 10px 20px;
    width: 374px;
    resize: none;
    background: $color-white;
    box-shadow: 2px 2px 12px rgba(111, 116, 162, 0.1);
    border-radius: 12px;
    height: 116px;
    border: none;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $color-black;

    @media screen and (max-width: 767px) {
      width: 100%;
    }
  }
}
