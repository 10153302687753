.customise {
  &__drop-table {
    padding: 28px 40px 36px 40px;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    background-color: $color-filter-popup;
    box-shadow: 2px 2px 6px rgba(120, 122, 143, 0.1);
    border-radius: 12px;
    border: 1px solid $color-grey;

    ul {
      margin-top: 16px;
      li {
        @include flex($align: center, $justify: space-between);
        padding-right: 14px;
        padding-left: 16px;
        width: 300px;
        margin-bottom: 12px;
        transition: border 0.1s ease-in-out;

        &:last-of-type {
          margin-bottom: 20px;
        }

        &:hover {
          border: 1px solid $color-blue;
        }

        span {
          @include flex($align: center);

          svg {
            margin-right: 13px;
          }
        }

        svg {
          &:hover {
            path {
              stroke: $color-dark-blue;
              fill: $color-white;
            }
          }
        }

        .toggle {
          margin-top: 10px;
        }
      }
    }
  }

  .button {
    width: 132px;
  }

  &__title {
    @include flex($align: center, $justify: space-between);
  }

  &__label_blue {
    color: $color-blue;
    cursor: pointer;
    font-weight: bold;
    transition: color 0.2s ease-in-out;

    &:hover {
      color: $color-dark-blue;
    }
  }
}
