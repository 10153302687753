.button {
  @include flex($align: center, $justify: center);
  border: 0;
  outline: 0;
  transition: all 0.2s ease-in-out;
  white-space: nowrap;
  padding: 0 15px;
  background-color: $color-blue;

  svg {
    margin-right: 8px;

    &:hover {
      path {
        fill: $color-white !important;
      }
    }
  }

  &:hover {
    cursor: pointer;
    transform: translateY(-1.5px);
  }
  &__text {
    display: flex;
    align-items: center;
    .spinner {
      --spinnerColor: $color-white;
    }
    svg {
      margin-left: 8px;

      path {
        fill: $color-white !important;
      }
    }
  }
  &____transparent {
    background-color: transparent;
    border: 1px solid $color-blue;
    color: $color-blue;
    .spinner {
      --spinnerColor: #{$color-blue};
    }
    svg {
      margin-left: 8px;

      path {
        fill: $color-blue !important;
      }
    }
  }
  &____label {
    background-color: transparent;
    border: none;
    padding: 0;
    color: $color-blue;
    justify-content: flex-start;
    .spinner {
      --spinnerColor: #{$color-blue};
    }
    svg {
      margin-left: 8px;

      path {
        fill: $color-blue !important;
      }
    }
  }
  span {
    transition: opacity 0.5s ease-in-out;
  }
  .spinner {
    position: absolute;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s ease-in-out;
  }
}
.button____transparent.button__loading,
.button____button.button__loading {
  span {
    opacity: 0;
  }
  .spinner {
    opacity: 1;
    visibility: visible;
  }
}
.button____label.button__loading {
  .spinner {
    margin-left: 10px;
    position: relative;
    opacity: 1;
    visibility: visible;
  }
}

.button____disabled {
  background-color: $color-purple-grey !important;
  border-color: $color-purple-grey !important;
  color: $color-white !important;
}
