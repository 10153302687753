.edit-policy-form {
  &__buttons {
    display: flex;
    margin-top: 40px;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      max-width: 374px;
      margin: 0 auto;
    }

    .button {
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    .button[type="submit"] {
      background-color: $color-blue;
    }

    button:first-of-type {
      margin-right: 30px;

      @media screen and (max-width: 767px) {
        margin-top: 40px;
        margin-bottom: 10px;
      }
    }
  }
}

.policy-form {
  width: 830px;
  padding-bottom: 50px;

  @media screen and (max-width: 767px) {
    width: auto;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__buttons {
    width: 100%;
    margin-top: 40px;

    @media screen and (max-width: 767px) {
      margin: 0 auto;
      margin-top: 40px;
      max-width: 335px;
    }

    & .button {
      @media screen and (max-width: 767px) {
        max-width: 374px;
        width: 100%;
      }
    }
  }

  &__date-toggler {
    margin-top: 11px;
    display: flex;
    align-items: center;
  }

  &__date-toggler-text {
    margin-left: 11px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }

  &__date-selector-label {
    color: $color-black;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 8px;
    font-weight: bold;
    display: inline-block;
  }

  &__file-selector-label {
    color: $color-black;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 8px;
    font-weight: bold;
    display: inline-block;
  }

  &__date-selector {
    margin-bottom: 31px;

    @media screen and (max-width: 767px) {
      width: 100%;
      order: 2;
    }
  }

  &__file-selector {
    @media screen and (max-width: 767px) {
      width: 100%;
      order: 4;
    }
  }

  &__emails {
    .form-input__email {
      margin-bottom: 5px;
    }
    .policy-form__add-email {
      display: flex;
      margin-top: 22px;
      align-items: center;
      cursor: pointer;

      p {
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        color: $color-blue;
        margin-left: 8px;
      }
    }
  }
  &__email-item {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  &__email-delete-icon {
    margin: 0px 5px 0px 10px;
    cursor: pointer;
  }

  .plus-icon {
    fill: $color-blue;
  }

  .notifications-picker__add-notifications {
    margin-bottom: 38px;
  }
}
