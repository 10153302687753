.form-file-input {
  height: 44px;
  display: flex;
  align-items: center;
  background-color: $color-white;
  box-shadow: 2px 2px 12px rgba(111, 116, 162, 0.1);
  border-radius: 12px;
  width: 374px;
  cursor: pointer;
  padding-left: 20px;
  position: relative;

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  &__text {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    max-width: 75%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  &__placeholder {
    color: $color-purple-grey;
  }

  &__input {
    display: none;
  }

  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 23px;
  }
}
