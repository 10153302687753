.checkbox-component {
  height: 14px;
  width: 14px;
  border-radius: 3px;
  border: 2px solid $color-purple-grey;
  cursor: pointer;
}

.checkbox-component--active {
  background-color: $color-blue;
  border: 2px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
}
