.input-text {
  background: $color-white;
  box-shadow: 2px 2px 12px rgba(111, 116, 162, 0.1);
  border-radius: 12px;
  border: 1px solid transparent;
  padding: 10px 20px;
  font-size: 14px;
  line-height: 24px;
  width: 100%;

  &:focus {
    outline: none;
    border: 1px solid $color-blue;
  }

  &_error {
    border: 1px solid $color-dark-pink;
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px $color-white inset;
    box-shadow: 0 0 0px 1000px $color-white inset;
  }
}

.input-radio {
  display: none;
}

.radio-label {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 16px;
  user-select: none;
  font-size: 14px;

  .checked {
    &::after {
      opacity: 1 !important;
    }
  }
}

.radio-button {
  height: 14px;
  width: 14px;
  border: 2px solid $color-purple-grey;
  border-radius: 50%;
  display: inline-block;
  margin-right: 8px;
  position: relative;

  &::after {
    height: 6px;
    width: 6px;
    content: "";
    display: block;
    border-radius: 50%;
    border: 6px solid $color-blue;
    opacity: 0;
    transition: opacity 0.2s;
    position: absolute;
    top: -2px;
    left: -2px;
  }
}

.selected {
  border: 2px solid $color-blue !important;
}

.input-radio:checked ~ .radio-label .radio-button::after {
  opacity: 1;
}
