.manage-users {
  &__container {
    max-width: 100%;
    margin: 0 auto;

    @media screen and (max-width: 767px) {
      position: relative;
    }

    h1 {
      @media screen and (max-width: 767px) {
        font-size: 32px;
      }
    }

    .button {
      width: 160px;
      background-color: $color-blue;

      @media screen and (max-width: 767px) {
        width: 40px;
        height: 40px;
        position: absolute;
        top: 10px;
        right: 0;
        padding: 0;

        & span {
          display: none;
        }

        & svg {
          margin: 0;
        }
      }
    }
  }

  &__table {
    margin-top: 32px;
    overflow-x: auto;

    .table {
      width: 100%;

      svg {
        cursor: pointer;
      }

      thead {
        tr {
          td {
            &:first-of-type {
              padding-left: 16px;
            }
          }
        }
      }

      tbody {
        tr {
          box-shadow: 2px 2px 6px rgba(120, 122, 143, 0.1);
          height: 80px;

          td {
            vertical-align: middle;
            padding: 0;

            &:first-of-type {
              padding-right: 58px;
              padding-left: 16px;
            }

            &:last-of-type {
              @include flex($align: center, $justify: flex-end);
              height: 80px;
              margin-right: 27px;
            }
          }
        }
      }
    }
  }

  &__search-block {
    @include flex($align: center, $justify: space-between);
    margin-top: 24px;
  }
}
