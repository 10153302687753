@use "sass:math";

$size: 5px;
$innerSize: 5px;
$width: 2px;
$duration: 0.7s;
$color: $color-white;

@mixin spiner_part($left, $top, $delay, $rotate) {
  animation-delay: $delay;
  transform: translate($left, $top) rotate($rotate);
}

.spinner {
  --spinnerColor: #{$color-white};
  position: relative;
  width: ($size + $innerSize) * 2;
  height: ($size + $innerSize) * 2;

  &_part {
    position: absolute;
    background-color: transparent;
    width: $size;
    height: $width;
    border-radius: 2px 2px 2px 2px;
    animation-name: fadeG;
    animation-duration: $duration;
    animation-iteration-count: infinite;
    animation-direction: normal;
  }
  @for $i from 1 through 8 {
    &__#{$i} {
      @include spiner_part(
        calc(
          ($size + $innerSize * 2) / 2 + math.cos($i * 45deg) *
            ($size + $innerSize * 2) / 2
        ),
        calc(
          ($size + $innerSize * 2) / 2 + math.sin($i * 45deg) *
            ($size + $innerSize * 2) / 2
        ),
        calc($i * $duration / 8),
        calc($i * 45deg)
      );
    }
  }
}

@keyframes fadeG {
  0% {
    background-color: var(--spinnerColor);
  }

  100% {
    background-color: transparent;
  }
}
