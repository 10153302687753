.form-input--invalid {
  .form-input__field {
    border: 1px solid red;
  }
}

.form-input {
  width: 374px;
  margin-bottom: 28px;

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  &__error-message {
    color: $color-dark-pink;
    font-size: 14px;
    transform: translateY(5px);
  }

  &__label {
    color: $color-black;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 8px;
    font-weight: bold;
    display: block;

    @media screen and (max-width: 767px) {
      display: block;
    }
  }

  &__field {
    background-color: $color-white;
    box-shadow: 2px 2px 12px rgba(111, 116, 162, 0.1);
    border-radius: 12px;
    border: 1px solid $color-white;
    padding: 10px 20px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $color-black;

    @media screen and (max-width: 767px) {
      padding: 10px 0;
    }

    &::placeholder {
      color: $color-purple-grey;
    }
  }

  &__label-toggle {
    display: flex;
    align-items: center;
  }

  &__label-with-toggle {
    display: inline-block;
  }

  &__price-toggler {
    display: flex;
    align-items: center;
    margin-bottom: 11px;
    margin-left: 70px;
  }

  &__toggler-text {
    margin-left: 11px;
  }
}

.policy-form__emails {
  @media screen and (max-width: 767px) {
    order: 2;
  }
}

.form-input__coverage-amount {
  @media screen and (max-width: 767px) {
    order: 4;
  }
}
.notifications-picker {
  @media screen and (max-width: 767px) {
    order: 3;
  }
}
