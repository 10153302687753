.policy-table {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;

  &__fields {
    width: 100%;
  }

  &__field {
    width: 100%;
  }

  &__field:not(:last-of-type) {
    border-bottom: 1px solid $color-grey;
  }

  &__grouped-field {
    margin-bottom: 32px;
    width: 100%;
  }

  &__grouped-title {
    background-color: rgba(236, 238, 245, 0.5);
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    min-width: 1118px;
    line-height: 17px;
    color: $color-purple-grey;
    padding: 9px 0 10px 16px;
  }
}

.policy-table-tips {
  background-color: $color-white;
  box-shadow: 2px 2px 6px rgba(120, 122, 143, 0.1);
  border-radius: 12px;
  position: absolute;
  z-index: 1;
  top: 10px;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;

  &:hover {
    opacity: 1;
    visibility: visible;
  }

  &__tip {
    height: 44px;
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $color-black;
    padding-left: 39px;
    padding-right: 20px;
    border-bottom: 1px solid $color-grey;
    position: relative;

    &::before {
      content: "";
      position: absolute;
      height: 8px;
      width: 8px;
      border-radius: 2px;
      background-color: $color-dark-pink;
      left: 20px;
    }

    &:nth-of-type(2) {
      &::before {
        background-color: $color-warn;
      }
    }

    &:nth-of-type(3) {
      &::before {
        background-color: $color-turquoise;
      }
    }
  }

  &__tip:last-of-type {
    border-bottom: none;
  }
}

.policy-table-header--grouped {
  margin-bottom: 16px;
}

.policy-table-header {
  display: flex;
  padding: 13px 16px;
  background-color: $color-grey;
  position: relative;
  min-width: 1100px;

  @media screen and (max-width: 767px) {
    display: none;
  }

  &__checkbox {
    display: flex;
    justify-content: flex-end;
  }

  &__field {
    display: inline-flex;
    align-items: center;

    p {
      font-weight: 500;
      font-size: 13px;
      line-height: 17px;
      color: $color-purple-grey;
      cursor: pointer;
    }
  }

  &__sort-icon {
    margin-left: 4px;
    cursor: pointer;
    transition: all 0.3s;
  }

  &__sort-icon--active {
    transform: rotate(180deg);
  }

  &__tips-icon {
    cursor: pointer;
    margin-right: 4px;

    &:hover {
      & + ul {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.notifications-tips {
  border: 1px solid $color-grey;
  box-shadow: 2px 2px 6px rgba(120, 122, 143, 0.1);
  border-radius: 12px;
  position: fixed;
  right: 40px;
  width: 235px;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  background-color: $color-white;
  transition: all 0.2s;

  &__title {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
    padding-left: 20px;
    color: $color-black;
    padding-top: 10px;
    margin-bottom: 10px;
  }

  &__notification {
    height: 44px;
    display: flex;
    padding-left: 20px;
    align-items: center;
    border-top: 1px solid $color-grey;
  }
  &__resend {
    color: $color-blue;
    cursor: pointer;
    font-weight: bold;
    font-size: 13px;
  }
}

.policy-table-field {
  background-color: $color-white;
  padding: 17px 16px 14px 19px;
  box-shadow: 2px 2px 6px rgba(120, 122, 143, 0.1);
  transition: all 0.2s;
  display: flex;
  min-height: 49px;
  cursor: pointer;
  min-width: 1100px;

  &:hover {
    background-color: $color-light-blue;
  }
  &__item {
    display: flex;
    align-items: center;
  }
  &__item:last-of-type {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
    min-width: auto;
    margin-bottom: 8px;
    position: relative;
  }

  &__item {
    @media screen and (max-width: 767px) {
      width: 100% !important;
    }
  }
}

.policy-table-field--expired {
  background-color: $color-expired;
}

.policy-name {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  width: 90%;
  color: $color-black;
  position: relative;
  height: 100%;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
  }

  &__name {
    margin-bottom: 1px;
  }

  &__subtext {
    font-weight: bold;
    font-size: 14px;
    line-height: 24px;
  }

  &__subtext--expired {
    color: $color-dark-pink;
  }

  &__mobile-name {
    display: none;
    font-size: 14px;
    line-height: 18px;
    color: $color-purple-grey;
    font-weight: 500;

    @media screen and (max-width: 767px) {
      display: block;
    }
  }
}

.policy-number {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  @media screen and (max-width: 767px) {
    margin-bottom: 8px;
  }

  &__number {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 90%;
  }

  &__company-name {
    width: 90%;
    font-size: 14px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: $color-purple-grey;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  &__mobile-number {
    display: none;
    font-size: 14px;
    line-height: 18px;
    color: $color-purple-grey;
    font-weight: 500;

    @media screen and (max-width: 767px) {
      display: block;
    }
  }
}

.policy-text {
  max-width: 90%;
  font-size: 14px;
  line-height: 24px;
  color: $color-black;
}

.policy-text--no-wrap {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.policy-notes {
  font-size: 14px;
  line-height: 24px;
  color: $color-black;

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.policy-notifications {
  position: relative;
  display: flex;
  justify-content: flex-end;

  @media screen and (max-width: 767px) {
    position: absolute;
    top: 17px;
    right: 16px;
  }

  &__edit {
    height: 14px;
    width: 14px;

    path {
      transition: all 0.3s;
    }

    &:hover {
      path {
        fill: $color-dark-blue;
      }
    }
  }

  &__notification-icon {
    position: relative;
  }

  &__notifications-count {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 12px;
    width: 12px;
    font-size: 9px;
    line-height: 8px;
    color: $color-white;
    background-color: $color-purple-grey;
    font-weight: 600;
    border-radius: 50%;
    top: -5px;
    left: 10px;
    transition: all 0.3s;
  }

  .notif-icon-svg {
    transition: all 0.3s;
  }

  &__notifications {
    width: 22px;
    height: 22px;
    margin-right: 30px;

    @media screen and (max-width: 767px) {
      display: none;
    }
  }

  &__attach {
    width: 22px;
    height: 22px;
    margin-right: 30px;

    path {
      transition: all 0.3s;
    }

    &:hover {
      path {
        fill: $color-dark-blue;
      }
    }
  }

  &__notifications:hover {
    .policy-notifications__notifications-count {
      background-color: $color-dark-blue;
    }

    .notifications-tips {
      opacity: 1;
      visibility: visible;
    }

    .notif-icon-svg {
      fill: $color-dark-blue;
    }
  }
}

.policy-name--danger {
  &::before {
    content: "";
    width: 3px;
    position: absolute;
    top: -17px;
    left: -19px;
    bottom: -14px;
    background-color: $color-dark-pink;
    border-radius: 10px;

    @media screen and (max-width: 767px) {
      bottom: -114px;
    }
  }
}

.policy-name--normal {
  &::before {
    content: "";
    left: -19px;
    width: 3px;
    position: absolute;
    top: -17px;
    bottom: -14px;
    background-color: $color-warn;
    border-radius: 10px;

    @media screen and (max-width: 767px) {
      bottom: -114px;
    }
  }
}

.policy-name--safe {
  &::before {
    content: "";
    width: 3px;
    position: absolute;
    top: -17px;
    bottom: -14px;
    left: -19px;
    background-color: $color-turquoise;
    border-radius: 10px;

    @media screen and (max-width: 767px) {
      bottom: -114px;
    }
  }
}

.policy-date {
  &__date {
    font-size: 14px;
    line-height: 24px;
    color: $color-black;

    @media screen and (max-width: 767px) {
      display: inline-block;
      margin-right: 10px;
    }
  }
  &__time {
    font-size: 14px;
    line-height: 24px;
    color: $color-purple-grey;

    @media screen and (max-width: 767px) {
      color: $color-black;
      display: inline-block;
    }
  }

  &__mobile-date {
    display: none;
    font-size: 14px;
    line-height: 18px;
    color: $color-purple-grey;
    font-weight: 500;

    @media screen and (max-width: 767px) {
      display: block;
    }
  }
}

.policy-update-date {
  @media screen and (max-width: 767px) {
    display: none;
  }
}
