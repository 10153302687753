.policy-info {
  &__title {
    @include flex($align: center, $justify: space-between);
    margin-top: 32px;
    margin-bottom: 16px;

    h1 {
      margin-top: 0;
    }

    &__buttons-block {
      @include flex($align: center);

      @media screen and (max-width: 767px) {
        display: none;
      }

      .button {
        width: 150px;

        &:first-of-type {
          margin-left: 0;
          margin-right: 28px;
        }
      }
    }
  }

  &__mobile-block {
    display: none;

    @media screen and (max-width: 767px) {
      display: flex;
      flex-direction: column;
      max-width: 335px;
      width: 100%;
      margin: 0 auto;
      padding-bottom: 10px;
    }

    .button:first-of-type {
      margin-right: 0;
      margin-bottom: 10px;
    }

    .button {
      @media screen and (max-width: 767px) {
        width: 100%;
      }
    }

    .button:first-child {
      background-color: $color-blue;
    }
  }

  &__resend {
    color: $color-blue;
    cursor: pointer;
    font-weight: 500;
    font-size: 13px;
  }

  &__info {
    &-row {
      @include flex();
      margin-bottom: 32px;

      p {
        width: 150px;
        word-wrap: break-word;
        font-weight: 700;
        font-size: 14px;
        line-height: 24px;
      }

      & > span {
        margin-left: 20px;
        line-height: 24px;
        max-width: 340px;
        word-wrap: break-word;
      }
    }
  }

  &__attachment {
    @include flex($align: center);
    color: $color-blue;
    font-weight: bold;
    cursor: pointer;

    svg {
      margin-right: 8px;
    }

    &:hover {
      color: $color-dark-blue;
    }
  }

  &__notifications {
    @include flex($align: left, $direction: column);
    & > span {
      margin-left: 20px;
      line-height: 24px;
      word-wrap: break-word;
    }
  }
}
