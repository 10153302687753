.warning-page {
  margin-top: 67px !important;

  h1 {
    margin-top: 32px;
  }

  p {
    font-size: 21px;
    font-weight: 600px;
    color: $color-purple-grey;
    margin-bottom: 42px;
  }

  .button {
    width: 210px;
  }
}
