.billing {
  padding-bottom: 80px;

  &__billing-item {
    margin-top: 32px;
    display: flex;
    flex: 0 0 30%;
    @media screen and (max-width: 767px) {
      flex: 0 0 100%;
      justify-content: center;
    }
  }

  h2 {
    margin-bottom: 18px;
  }

  &__container {
    @include flex($align: center);
    margin-top: 60px;
    margin-bottom: 60px;
  }

  &__card {
    padding: 28px 32px 44px 32px;
    border: 2px solid $color-light-grey;
    border-radius: 12px;
    width: 208px;
    transition: all 0.2s ease-in-out;
    margin-right: 32px;

    &:last-of-type {
      margin-right: 0;
    }

    h3 {
      font-size: 21px;
      font-weight: 600;
      line-height: 34px;
      border-bottom: 1px solid $color-light-grey;
      padding-bottom: 13px;
    }

    &__price {
      @include flex($align: flex-start);
      margin-top: 20px;
      padding-bottom: 17px;
      border-bottom: 1px solid $color-light-grey;

      span {
        &:first-of-type {
          color: $color-blue;
          font-weight: bold;
          font-size: 46px;
          line-height: 46px;
        }

        &:last-of-type {
          margin-left: 8px;
        }
      }
    }

    ul {
      margin-top: 20px;
      margin-bottom: 31px;

      li {
        font-weight: bold;
        font-size: 14px;
        line-height: 24px;
        list-style-type: disc;
        margin-left: 15px;

        &:last-of-type {
          margin-top: 5px;
        }
      }
    }

    &:hover {
      background-color: rgba(20, 77, 222, 0.06);
      border-color: $color-blue;
    }

    .transparent {
      margin-left: 0px;
    }

    &_selected {
      color: $color-light-blue;
      background-color: $color-blue;
      border-color: $color-blue;

      .billing__card__price {
        span {
          color: $color-light-blue;
        }
      }

      .button {
        color: $color-light-blue;
        border-color: $color-light-blue;
      }

      &:hover {
        background-color: $color-blue;
        border-color: $color-blue;
      }
    }
  }

  &__form {
    padding-top: 52px;
    border-top: 1px solid $color-light-grey;

    h2 {
      margin-bottom: 30px;
    }

    .form {
      .input-text {
        width: 374px;
      }

      .button {
        margin-top: 12px;
        width: 372px;
      }
    }
  }

  &__paylink {
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    width: 200px;
    height: 44px;
    border-radius: 12px;
    background-color: $color-blue;
    font-size: 14px;
    line-height: 24px;
    font-weight: 700;
    color: $color-white;
    pointer-events: none;

    &.active {
      pointer-events: auto;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
  }

  &__toggle-box {
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      margin-left: 12px;
    }

    @media screen and (max-width: 767px) {
      justify-content: flex-start;
      margin-top: 20px;
    }
  }

  &__billings-list {
    display: flex;
    margin-bottom: 60px;
    justify-content: space-between;

    li:not(:last-of-type) {
      @media screen and (max-width: 767px) {
        margin-right: 0;
      }
    }

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  &__proceed-button {
    max-width: 200px;
  }
}

.alert-overlay {
  height: 100vh;
  position: fixed;
  background-color: rgba(246, 247, 251, 0.6);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.alert {
  background-color: $color-white;
  width: 504px;
  padding: 40px 0 60px 60px;
  box-shadow: 2px 2px 12px rgba(111, 116, 162, 0.1);
  border-radius: 12px;
  position: relative;
  z-index: 10;

  &__title {
    color: $color-black;
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
    margin-bottom: 21px;
  }

  &__text {
    color: $color-black;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 40px;
  }

  &__buttons {
    display: flex;
  }

  &__button {
    max-width: 166px;
  }

  &__button:first-of-type {
    margin-right: 30px;
  }

  &__close-icon {
    position: absolute;
    right: 28.72px;
    top: 28.72px;
    cursor: pointer;
  }
}
