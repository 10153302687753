.fetch-status-popup {
  @include flex($align: center, $justify: space-between);
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 20;
  width: 310px;
  border-radius: 12px;
  padding: 17px 20px;
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;
  color: $color-white;
  background-color: $color-warn;

  @media screen and (max-width: 767px) {
    left: 0;
    bottom: 0;
    right: 0;
    width: auto;
    border-radius: 0;
  }

  svg {
    cursor: pointer;

    path {
      fill: $color-white;
    }
  }

  &_error {
    background-color: $color-dark-pink;
  }

  &_success {
    background-color: $color-turquoise;
  }
}
