.checkbox {
  &--inactive {
    border: 2px solid $color-purple-grey;
    border-radius: 3px;
    width: 12px;
    height: 12px;
    cursor: pointer;

    svg {
      opacity: 0;
      visibility: hidden;
      display: none;
    }
  }

  &--active {
    @include flex($align: center, $justify: center);
    border: 2px solid $color-purple-grey;
    border-color: $color-blue;
    background-color: $color-blue;
    border-radius: 3px;
    width: 12px;
    height: 12px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;

    &:hover {
      svg path {
        fill: $color-white;
      }
    }

    svg {
      margin: 0 !important;
      padding: 0 !important;
    }
  }
}
